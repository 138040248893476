(function () {
  'use strict';

  class SessionsCtrl {
    constructor(User, Classrooms, ModuleSet, $filter, $mdDialog, $state, $window, $mdToast, $scope) {
      let vm = this;
      vm.$scope = $scope;
      vm.User = User;
      vm.$filter = $filter;
      vm.$mdToast = $mdToast;
      vm.$mdDialog = $mdDialog;
      vm.Classrooms = Classrooms;
      vm.ModuleSet = ModuleSet;
      vm.selectedIndex = 0;
      vm.$state = $state;
      vm.$window = $window;
      vm.init();
    }
    init(selectedIndex = 0) {
      const vm = this;
      vm.loadingModule = false;
      vm.getClassrooms(selectedIndex);
    }
    containsObject(obj, list) {
      let i;
      for (i = 0; i < list.length; i++) {
        if (list[i].index === obj.index) {
          return i;
        }
      }
      return false;
    }
    catCounterSet(classroom, module_categories) {
      classroom.categoryCounter++;
      if (module_categories) {
        module_categories.answer = null;
      }
    }
    moduleCategories(query, classroom, mainQuery) {
      const vm = this;
      vm.ModuleSet.moduleCategories(query)
        .then((data) => {
          angular.forEach(classroom.module_set.module_categories, function (module_category, k, o) {
            const filter = vm.$filter('filter')(data.module_categories, {id: module_category.id}, true)[0];
            if (filter && mainQuery === true) {
              if (filter.is_main_query === true) {
                o[k].facet_values = filter.facet_values;
              } else {
                o[k].module_collection = filter.module_collection;
              }
            }
          });
        })
        .finally(() => {
          vm.loadingModule = false;
        });
    }
    setModuleQueries(module_category, group, classroom) {
      const vm = this,
          filter = {
            slug: module_category.slug,
            value: group ? group.answer.facet_value : module_category.answer,
            index: group ? group.id : module_category.id,
            group_id: group ? group.id : null,
            is_main_query: module_category.is_main_query
          };
      let key = vm.containsObject(filter, classroom.modulesQuery.categories);
      if (key === false) {
        classroom.modulesQuery.categories.push(filter);
      } else {
        classroom.modulesQuery.categories[key] = filter;
      }
      vm.moduleSetDocuments(group, classroom, false, module_category.is_main_query);
    }
    complete(currentSession, classrooms) {
      const vm = this;
      vm.Classrooms.completeModuleSessions({id: classrooms.id, session_id: currentSession.id});
    }
    getClassrooms(selectedIndex) {
      const vm = this;
      vm.Classrooms.classroomsIndex()
        .then((data) => {
          vm.classroomsIndex = data;
          if (selectedIndex) {
            vm.selectedIndex = selectedIndex;
          }
        });
    }
    getClassroom(classroom) {
      const vm = this;
      delete classroom.groups;
      delete classroom.module_set;
      vm.refreshValidation(classroom);
      vm.classroomView(classroom);
    }
    classroomView(classroom) {
      const vm = this;
      vm.Classrooms.classroomView({id: classroom.id})
        .then((data) => {
          classroom.groups = data.classroom.groups;
          classroom.module_set = classroom.module_sets && classroom.module_sets[0];
        });
      vm.getSession(classroom);
    }
    getSession(classroom) {
      const vm = this;
      vm.Classrooms.getModuleSession({id: classroom.id})
        .then((data) => {
          vm.sessionPayload = data;
        });
    }
    submitRun(classroom) {
      const vm = this,
          module_session = {
            id: classroom.id,
            module_set_id: classroom.module_set.id,
            categories: [],
            group_module_documents: []
          };
      angular.forEach(classroom.groups, function (group) {
        const groupCategories = vm.$filter('filter')(classroom.modulesQuery.categories, {group_id: group.id}, true),
            group_module_document = {
              group_id: group.id,
              module_id: group.selectedModule ? group.selectedModule.id : vm.selectedModule.id,
              categories: groupCategories
            };
        module_session.group_module_documents.push(group_module_document);
      });
      angular.forEach(classroom.modulesQuery.categories, function (v) {
        if (v.group_id === null) {
          module_session.categories.push({slug: v.slug, value: v.value});
        }
      });
      vm.Classrooms.createModuleSessions(module_session)
        .then(() => {
          // vm.init();
          vm.getClassroom(classroom);
        })
        .catch((payload) => {
          vm.message(...payload.data.message);
        });
    }
    refreshValidation(classroom) {
      classroom.categoryCounter = 0;
      classroom.modulesQuery = {categories: []};
    }
    message(msg = 'Groups cannot be assigned a module that was assigned in a previous session') {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
    moduleSetDocuments(group, classroom, refresh = false, mainQuery = false) {
      const vm = this;
      if (refresh === true) {
        vm.refreshValidation(classroom);
      }
      let payload = {
            query: angular.toJson(classroom.modulesQuery),
            id: classroom.module_set.id
          },
          queriesFiler = vm.$filter('filter')(classroom.modulesQuery.categories, {is_main_query: true}, true),
          filtersPayload = {
            query: angular.toJson({categories: queriesFiler}),
            id: classroom.module_set.id
          };
      vm.loadingModule = true;
      if (mainQuery === false) {
        if (group) {
          group.selectedModule = {
            id: group.answer.id
          };
        }
        vm.moduleCategories(filtersPayload, classroom, mainQuery);
      } else {
        vm.ModuleSet.moduleSetDocuments(payload)
          .then((data) => {
            vm.selectedModule = data.module_documents.length > 0 && data.module_documents[0];
            if (group) {
              group.selectedModule = vm.selectedModule;
            }
          })
          .finally(() => {
            vm.moduleCategories(filtersPayload, classroom, mainQuery);
          });
      }
    }
    deleteModuleSession(ev, moduleSession, classroom) {
      let vm = this,
          confirm = vm.$mdDialog.confirm()
            .title('Confirm')
            .textContent('Are you sure you want to delete this?')
            .ariaLabel('Confirm')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');
      vm.$mdDialog.show(confirm).then(function () {
        vm.Classrooms.deleteModuleSession({id: classroom.id, session_id: moduleSession.id})
          .then(()=> {
            vm.getSession(classroom);
          });
      });
    }
    goToResultsPage(classroom) {
      const vm = this,
          uri = vm.$state.href('dashboard.results', {classroom_id: classroom.id});
      vm.$window.open(uri, '_blank');
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.sessions.controller:SessionsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.sessions')
    .controller('SessionsCtrl', SessionsCtrl);
}());
